import React, { useState, useEffect } from "react";
import "../../../CSS/NewsFullView.scss";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase.js";
import { useParams } from "react-router-dom";

function NewsFullView(props) {
  const [itemData, setItemData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      const q = query(collection(db, "posts"), where("linkTopic", "==", slug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((snapshot, index) => {
          setItemData(JSON.parse(JSON.stringify(snapshot.data())));
        });
      } else {
        setItemData(null);
      }
    };

    fetchItem();
  }, [slug]);

  if (!itemData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="container text-justify px-3 contentNewTopic">
        <div className="borderNews px-4 py-4">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mb-12 d-flex">
              <h1 className="mt-2 text-center text-justify mb-5 titleFullView">
                {itemData.title}
              </h1>
            </div>
            <div className="row mb-3 mx-1 text-center">
              <div className="d-flex justify-content-between buttonSocial">
                <div
                  className="fb-like mt-2 ml-2"
                  data-href={
                    "https://group01-tintucbatdongsan.tk/" +
                    itemData.partURL_plugin_fb
                  }
                  data-layout="button_count"
                  data-action="like"
                  data-size="large"
                  data-share="true"
                ></div>
                <div className="wv-button-placeholder ml-1"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-12 mb-12">
              <img
                className="imgFullView text-justify text-center"
                src={itemData.imgsrc}
                alt="img"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
              <p className="mt-4 contentFullView text-justify">
                {itemData.description}
              </p>
            </div>
          </div>
        </div>

        <div className="row text-justify text-center mt-3">
          <div className="col-lg-12 col-sm-12 mb-12">
            <h4>Comments</h4>
            <div
              className="fb-comments"
              data-href={"http://localhost:3000/" + props.partURL_plugin_fb}
              data-numposts="10"
              data-width="900"
              data-mobile="Auto-detected"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsFullView;
