import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const News_Block = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(props.block_NavLinkFull);
  };

  return (
    <div
      className="list-group-item list-group-item-action mb-3"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex">
        <img
          src={props.block_img_src}
          alt="image from bất động sản"
          className="img-fluid mr-3"
          style={{ width: "200px", height: "200px", objectFit: "cover" }}
        />
        <div className="flex-grow-1">
          <h5 className="mb-1">{props.block_title}</h5>
          <p
            className="mb-1 text-truncate"
            style={{
              maxHeight: "50px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.block_small_content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default News_Block;
