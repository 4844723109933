import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Navbar, Nav } from "react-bootstrap";

const NavigationBar = () => {
  return (
    <Navbar bg="light" expand="md">
      <Navbar.Brand href="/"></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="ml-auto">
          <Nav.Link href="/home" className="nav-link text-dark">
            <i className="fas fa-home"></i> HOME
          </Nav.Link>
          <Nav.Link href="/search" className="nav-link text-dark">
            <i className="fas fa-search"></i> SEARCH
          </Nav.Link>
          <Nav.Link href="/newTopic" className="nav-link text-dark">
            <i className="fas fa-pencil-alt"></i> POST
          </Nav.Link>
          <Nav.Link href="/more" className="nav-link text-dark">
            <i className="fas fa-info-circle"></i> MORE
          </Nav.Link>
          <Nav.Link
            href="#login"
            className="nav-link loginButton text-dark"
            data-toggle="modal"
            data-target="#myModal"
          >
            <i className="fas fa-sign-in-alt"></i> LOGIN
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
