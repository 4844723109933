import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavigationBar from "./components/NavigationBar.js";
import New_Topic from "./components/New_Topic.js";

import Footer from "./components/Footer.js";
import More from "./components/More.js";

import "./CSS/Main.scss";
import "./CSS/Login.css";

import Home from "./components/Home/Home.js";
import NewsFullView from "./components/Home/news/NewsFullView";

const Main = () => {
  return (
    <>
      <NavigationBar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/newTopic" element={<New_Topic />} />

          <Route path="/detail/:slug" element={<NewsFullView />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
};

export default Main;
