import React, { Component } from "react";
import "../../CSS/header_home.scss";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="overlay" />

        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="media/videoBackground.mp4" type="video/mp4" />
        </video>

        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3 title_header">Định Giá Bất Động Sản</h1>
              <p className="lead mb-0 title_content">
                dinhgiabds.com cung cấp quá trình xác định giá trị thực của một
                tài sản bất động sản dựa trên nhiều yếu tố như vị trí, diện
                tích, tiện ích, tình trạng pháp lý và thị trường hiện tại. Đánh
                giá chính xác giúp người mua và bán đưa ra quyết định hợp lý và
                hiệu quả.
              </p>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
