import React, { useState, useEffect } from "react";
import News_Block from "./News_Block.js";
import { db } from "../../../firebase.js";
import { collection, getDocs } from "firebase/firestore";
// import { formatRelative } from "date-fns/esm";
import "bootstrap/dist/css/bootstrap.min.css";

const Group_News = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, "posts"));
      const postQuery = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sắp xếp các bài đăng theo thời gian mới nhất
      postQuery.sort((a, b) => b.timestamp - a.timestamp);

      setPosts(postQuery);
    };

    fetchPosts();
  }, []);

  // function formatDate(seconds) {
  //   let formattedDate = "";

  //   if (seconds) {
  //     formattedDate = formatRelative(new Date(seconds * 1000), new Date());
  //     formattedDate =
  //       formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  //   }

  //   return formattedDate;
  // }

  return (
    <div className="container">
      <h3 className="my-4 text-justify text-center">Tin nổi bật</h3>
      <div className="list-group">
        {posts.map((post) => {
          return (
            <News_Block
              key={post.id}
              id={post.id}
              block_NavLinkFull={`/detail/${post.linkTopic}`}
              block_img_src={post.imgsrc}
              block_title={post.title}
              block_small_content={post.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Group_News;
