import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Slug from "slug";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase.js";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

function New_Topic() {
  const [image, setImage] = useState(null);
  const [slug, setSlug] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "posts"), {
        id: uuidv4(),
        imgsrc: imageUrl,
        linkTopic: Slug(title),
        title: title,
        description: description,
      });

      // Reset form fields after submission
      setImage(null);
      setTitle("");
      setDescription("");
      setImageUrl("");
      setProgress(0);
      setUploadSuccess(true); // Set upload success state
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);

      const storageRef = ref(storage, `images/${e.target.files[0].name}`);
      const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setImageUrl(downloadURL);
          setUploadSuccess(false); // Reset upload success state
          // alert("Upload successful!");
        }
      );
    }
  };

  return (
    <div className="container mt-5">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/">
            <span className="fas fa-globe"> </span> Home
          </a>
        </li>
        <li className="breadcrumb-item active">Post</li>
      </ul>
      <h2 className="mt-4 mb-4">Share your news with us</h2>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <form className="needs-validation" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="description"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Choose Image
              </label>
              <input
                required
                type="file"
                className="form-control"
                id="image"
                onChange={handleChange}
              />
              {image && (
                <div>
                  <progress value={progress} max="100" />
                  {uploadSuccess && (
                    <div className="alert alert-success mt-3" role="alert">
                      Upload successful!
                    </div>
                  )}
                  <div className="mt-3">
                    <img
                      src={imageUrl}
                      alt="Uploaded"
                      className="img-thumbnail"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                    {/* <p className="mt-2">
                      Image URL: <a href={imageUrl}>{imageUrl}</a>
                    </p> */}
                  </div>
                </div>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default New_Topic;
